export const DEFAULT_PAGE_LIMIT = '10';

/*
  States of a Row Value
  Row data is Valid EntityValidated
  Row data is executed and entity is created: EntityCreated
  Row data is inValid and skipped: EntityInValidated
*/
export enum RowValueState {
  EntityValidated = 'ENTITY_VALIDATED',
  EntityCreated = 'ENTITY_CREATED',
  EntityInValidated = 'ENTITY_INVALIDATED',
  EntityCreateFailed = 'ENTITY_CREATE_FAILED',
}
