







































































































































































import { Component, Watch } from 'vue-property-decorator';

import DataApiManager from '@/api/dataApiManager';
import { BaseVue } from '@/BaseVue';
import DataSourceCreate from '@/components/data/DataSourceCreate.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiLoading from '@/components/ui/UiLoading.vue';

import { DataSvcPipeRequest, DataSvcPipeStep, DataSvcWizardRequest } from '../../../generated/data-svc';
import DataSourceUpload from '../../components/data/DataSourceUpload.vue';
import UploadImport from '../../components/tools/UploadImport.vue';
import ValidateImport from '../../components/tools/ValidateImport.vue';
import UiStepper from '../../components/ui/UiStepper.vue';
import UiTabs2 from '../../components/ui/UiTabs2.vue';
import Explore from '../../views/data/Explore.vue';
import DataImportReport from './DataImportReport.vue';
import { standardizeState } from './DataImportUtils';

export enum WizardStatus {
  Completed = 'Completed',
  ValidatingRecords = 'ValidatingRecords',
  CreatingRecords = 'CreatingRecords',
  Validated = 'Validated',
  Preview = 'Preview',
  Open = 'Open',

  // when wizard is failing
  Failed = 'Failed', // last step of the wizard
}

@Component({
  components: {
    UiButton,
    UiLoading,
    UiStepper,
    UiTabs2,
    UploadImport,
    DataSourceCreate,
    ValidateImport,
    DataSourceUpload,
    DataImportReport,
    Explore,
  },
})
export default class DataImport extends BaseVue {
  declare register?: any;
  public dataSourceId = '';
  public isHideImportStep = false;
  tabListStandardTransaction = ['Data Preview', 'Import Summary'];
  tabListBtcWallet = ['Raw Data Preview', 'Transformed Data Preview'];
  tabListForNonBitwaveStandardTransaction = ['Raw Data Preview', 'Transformed Data Preview', 'Import Summary'];
  public validationExecutionId = '';
  public schemaId = '';
  public wizardReportId = this.$route.params.reportId;

  public creationExecutionId = '';
  public status: WizardStatus = WizardStatus.Open;
  public isLoadingWizardReport = true;
  public nextButtonLoading = false;
  public previousButtonLoading = false;
  public nextButtonLabels = ['Next', 'Generate Preview', 'Validate', 'Run Import'];
  public wizardState = {
    failure: false,
    showAppend: false,
  };

  async mounted() {
    await this.loadWizardReportById();
  }

  async uploadSuccessCallback() {
    // Reset workflows on new uploads
    this.validationExecutionId = '';
    this.creationExecutionId = '';
    this.status = WizardStatus.Preview;
    await this.saveWizardProgress({
      TotalTransactions: '',
      ValidatedTransaction: '',
      CreatedTransaction: '',
    });

    // reset wizard state failure
    this.wizardState.failure = false;
  }

  async loadWizardReportById() {
    this.isLoadingWizardReport = true;
    const ds = DataApiManager.getInstance();
    try {
      const response = await ds.getWizardReportById(this.orgId, this.wizardReportId, { withCredentials: true });
      if (response.status === 200) {
        const record = response.data.items[0];
        if (record) {
          this.dataSourceId = record.DataSourceId ?? '';
          this.schemaId = record.SchemaId ?? '';
          this.validationExecutionId = record.ValidationWorkflowExecutionId ?? '';
          this.creationExecutionId = record.CreationWorkflowExecutionId ?? '';
          this.status = record.Status ?? '';
          if (this.status === WizardStatus.Preview) {
            this.currentStepIndex = 2;
          }
          if ([WizardStatus.ValidatingRecords, WizardStatus.Validated].includes(this.status)) {
            this.currentStepIndex = 3;
          }
          if ([WizardStatus.CreatingRecords, WizardStatus.Completed].includes(this.status)) {
            this.currentStepIndex = 4;
          }
        }
      } else {
        this.showErrorSnackbar('Wizard Report Fetch failed');
      }
    } catch (err) {
      this.showErrorSnackbar('Wizard Report Fetch failed');
      console.log(err);
    } finally {
      this.isLoadingWizardReport = false;
    }
  }

  validateStep1() {
    const createDataSource = this.$refs.createDataSource as any;
    // data source id already exist, i.e. loaded from saved wizard
    if (this.dataSourceId) {
      return true;
    }
    // Both name and schema are selected
    if (createDataSource.$children[0].value && createDataSource.$children[1].value) {
      return true;
    }
    this.showErrorSnackbar('Create/ Select Data Source before continuing to next step');
    return false;
  }

  validateStep2() {
    return true;
  }

  validateStep3() {
    // No user inputs are required on the preview stage, step is valid by default for now
    if ((this.$refs.explore as any).dataSourceItems.length) {
      return true;
    }
    this.showErrorSnackbar('Please Upload files to the data source before proceeding');
    return false;
  }

  validateStep4() {
    // Check if there are invliad items found du
    const validationReport = this.$refs.validationReport as any;
    if (validationReport.validationFailedLength > 0) {
      this.showErrorSnackbar('Please Fix invalid items before proceeding to create');
      return false;
    }
    return true;
  }

  isStepValid() {
    switch (this.currentStepIndex) {
      case 0:
        return this.validateStep1();
      case 1:
        return this.validateStep2();
      case 2:
        return this.validateStep3();
      case 3:
        return this.validateStep4();
      default:
        return false;
    }
  }

  standardizeState(name: string): string {
    return standardizeState(name);
  }

  previousButtonCallbackFn() {
    if (this.currentStepIndex > 0) {
      if (this.currentStepIndex === 1 && this.status === WizardStatus.Open) {
        // if you go back to data source create page, reset data source id
        this.dataSourceId = '';
      }
      this.currentStepIndex--;
      return true;
    }
  }

  async step1Callback() {
    if (this.dataSourceId) {
      return true;
    }
    const createDataSource = this.$refs.createDataSource as any;
    this.schemaId = createDataSource.newDataSourceSchema.id;
    if (this.schemaId === 'btc-wallet') {
      this.isHideImportStep = true;
    }
    const response = await createDataSource.onCreateDataSourceButtonClick();
    if (response?.status === 200) {
      this.dataSourceId = response.data.id;
      return true;
    }
    return false;
  }

  step2Callback() {
    return true;
  }

  get isWizardLocked(): boolean {
    return [WizardStatus.Completed, WizardStatus.CreatingRecords].includes(this.status);
  }

  // As a next step to preview step, we trigger the workflow to process the data loaded
  async step3Callback() {
    if (this.validationExecutionId || this.creationExecutionId) {
      return true;
    }
    // Set schema id, if existing data source was selected
    if (!this.schemaId) {
      this.schemaId = (this.$refs.explore as any).dataSources.filter(
        (item: any) => item.id === this.dataSourceId
      )[0].schemaId;
    }
    const repsonse = await this.pipe(this.dataSourceId, this.schemaId, this.wizardReportId, DataSvcPipeStep.Validation);

    if (repsonse?.status === 200) {
      this.validationExecutionId = repsonse.data.executionId.split('executions/')[1];
      this.status = WizardStatus.ValidatingRecords;
      return true;
    }
    this.showErrorSnackbar('Validation Job Submission failed');
    return false;
  }

  async step4Callback() {
    if (this.creationExecutionId) {
      return true;
    }
    const repsonse = await this.pipe(this.dataSourceId, this.schemaId, this.wizardReportId, DataSvcPipeStep.Creation);
    if (repsonse?.status === 200) {
      this.creationExecutionId = repsonse.data.executionId.split('executions/')[1];
      this.status = WizardStatus.CreatingRecords;
      return true;
    }
    this.showErrorSnackbar('Creation Job Submission failed');
    return false;
  }

  public async pipe(
    dataSourceId: string,
    sink = 'wallet',
    wizardReportId = '',
    step: DataSvcPipeStep = DataSvcPipeStep.Validation
  ) {
    const ds = DataApiManager.getInstance();
    const req: DataSvcPipeRequest = {
      dataSourceId,
      sink,
      wizardReportId,
      step,
    };
    try {
      const resp = await ds.pipe(this.orgId, req, { withCredentials: true });
      if (resp.status === 200) {
        return resp;
      } else {
        throw new Error((resp.data as any).message);
      }
    } catch (err) {
      this.showErrorSnackbar(`Piping failure ${err}`);
    }
  }

  async saveWizardProgress(params = {}) {
    const ds = DataApiManager.getInstance();
    const { reportId } = this.$route.params;
    const DataSourceId = this.dataSourceId;
    const userObj = this.$store.state.user;
    const payload: DataSvcWizardRequest = {
      ...params,
      WizardReportId: reportId,
      OrgId: this.orgId,
      DataSourceId,
      SchemaId: this.schemaId,
      User: { name: userObj?.displayName ?? '', id: userObj?.id ?? '' },
      ValidationWorkflowExecutionId: this.validationExecutionId,
      CreationWorkflowExecutionId: this.creationExecutionId,
      Status: this.status,
    };
    const resp = await ds.wizard(this.orgId, payload, { withCredentials: true });
    if (resp.status === 200) {
      return resp.data;
    }
    this.showErrorSnackbar('Save Wizard Failed');
    return false;
  }

  async nextButtonCallbackFn() {
    if (this.isStepValid()) {
      this.nextButtonLoading = true;
      let stepResult = false;
      let saveResult = false;
      if (this.isWizardLocked) {
        if (this.currentStepIndex < this.dynamicSteps().length) {
          this.currentStepIndex++;
          this.nextButtonLoading = false;
          return true;
        }
      }
      switch (this.currentStepIndex) {
        case 0:
          // Create Data source step
          stepResult = await this.step1Callback();
          if (stepResult) {
            saveResult = await this.saveWizardProgress();
          }
          break;
        case 1:
          // Upload step
          stepResult = this.step2Callback();
          saveResult = true;
          break;
        case 2:
          stepResult = await this.step3Callback();
          saveResult = await this.saveWizardProgress();
          break;
        case 3:
          stepResult = await this.step4Callback();
          saveResult = await this.saveWizardProgress();
          break;
      }
      this.nextButtonLoading = false;
      if (stepResult && saveResult && this.currentStepIndex < this.dynamicSteps().length) {
        this.currentStepIndex++;
        return true;
      }
    }
    return false;
  }

  submitButtonCallbackFn() {
    console.log('placeholder function');
  }

  currentStepIndex = 0;

  dynamicSteps() {
    const steps = [
      { id: 'Step 1', name: 'Name' },
      { id: 'Step 2', name: 'Upload' },
      { id: 'Step 3', name: 'Preview' },
      { id: 'Step 4', name: 'Validate' },
    ];

    if (this.schemaId !== 'btc-wallet') {
      steps.push({ id: 'Step 5', name: 'Import' });
    }

    return steps;
  }

  // update on validation status response
  validationStatus = (status: string) => {
    const hasFailed = status === 'failed';
    this.wizardState.failure = hasFailed;
    if (hasFailed) this.status = WizardStatus.Failed;
  };

  @Watch('currentStepIndex')
  currentStepChanged(currStepIndex: number, prevStepIndex: number) {
    if (currStepIndex === 1 && currStepIndex > prevStepIndex && this.status !== WizardStatus.Preview) {
      this.wizardState.failure = true;
      this.status = WizardStatus.Failed;
      return;
    }

    // re-visit to upload step
    if (currStepIndex === 1 && prevStepIndex > 1) {
      this.wizardState.showAppend = true;
    }

    // reset failure state when backward
    if (currStepIndex < 3 && currStepIndex < prevStepIndex) {
      this.wizardState.failure = false;
    }
  }
}
