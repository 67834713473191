var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-text-gray-900 tw-font-sans",staticStyle:{"font-size":"16px"}},[_c('div',{staticClass:"tw-border-b tw-border-gray-300"},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5"},[_c('h3',{staticClass:"tw-text-2xl tw-capitalize tw-font-medium"},[_vm._v("Reconciliations")]),(_vm.showRuns)?_c('ui-button',{nativeOn:{"click":function($event){$event.stopPropagation();_vm.showNewDialog = true}}},[_vm._v("New Reconciliation")]):_vm._e()],1)]),_c('div',{staticClass:"tw-w-full tw-border-b tw-border-gray-300"},[_c('div',{staticClass:"tw-pl-4 sm:tw-pl-6 lg:tw-pl-8 tw-text-gray-800 tw-flex tw-items-center tw-justify-between tw-pt-1 tw-mb-1"},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('fa',{staticClass:"tw-h-4 tw-w-5 tw-py-0.5 tw-mr-4 tw-text-neutral-500 tw-text-primary-300 tw-cursor-pointer",attrs:{"icon":"fa-solid fa-arrow-rotate-right"},on:{"click":_vm.refresh}})],1)])]),_c('div',{staticClass:"tw-p-4 tw-w-full tw-h-full"},[_c('div',{staticClass:"tw-mt-4 tw-border tw-border-gray-300"},[_c('ui-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reconciliations,"isLoading":!!_vm.isLoading,"no-data-message":"There are no reconciliations to display."},scopedSlots:_vm._u([{key:"td-reconciliation",fn:function(ref){
var rec = ref.item;
return [_c('td',{staticClass:"tw-text-left"},[_c('div',{staticClass:"tw-space-x-2 tw-whitespace-nowrap"},[_vm._v(" "+_vm._s(rec.reconciliation)+" ")])])]}},{key:"td-status",fn:function(ref){
var rec = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(rec.status)+" ")])])]}},{key:"td-approver",fn:function(ref){
var rec = ref.item;
return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-col"},[_vm._v(" "+_vm._s(rec.approver)+" ")])])]}},{key:"td-date",fn:function(ref){
var rec = ref.item;
return [_c('td',[_c('a',{staticClass:"tw-text-blue-500 tw-underline",attrs:{"href":("#/reconciliations/" + (rec.id))}},[_vm._v(_vm._s(rec.date))])])]}},{key:"td-actions",fn:function(ref){
var rec = ref.item;
return [_c('td',[_c('button',{staticClass:"tw-relative tw-inline-flex tw-h-6 tw-w-11 tw-flex-shrink-0 tw-cursor-pointer tw-rounded-full tw-border-2 tw-border-transparent tw-transition-colors tw-duration-200 tw-ease-in-out tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-indigo-600 tw-focus:ring-offset-2",class:{ 'tw-bg-indigo-600': rec.complete, 'tw-bg-gray-200': !rec.complete },attrs:{"type":"button","role":"switch","aria-checked":"false"},on:{"click":function($event){return _vm.completeRec(rec.id)}}},[_c('span',{staticClass:"tw-sr-only"},[_vm._v("Use setting")]),_c('span',{staticClass:"tw-translate-x-0 tw-pointer-events-none tw-inline-block tw-h-5 tw-w-5 tw-transform tw-rounded-full tw-bg-white tw-shadow tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out",class:{ 'tw-translate-x-5': rec.complete, 'tw-translate-x-0': !rec.complete },attrs:{"aria-hidden":"true"}})])])]}}])})],1)]),(_vm.showNewDialog)?_c('ui-modal',{on:{"close":function($event){_vm.showNewDialog = false}}},[_c('div',{staticClass:"tw-pb-8"},[_c('div',{staticClass:"tw-mt-4"},[_c('ui-select2',{attrs:{"keyField":"id","labelField":"name","label":"Reconciliation Template","items":[{ id: 1, name: 'Monthly Client Balance Rec' }]},model:{value:(_vm.recTemplate),callback:function ($$v) {_vm.recTemplate=$$v},expression:"recTemplate"}}),_c('ui-date-picker2',{staticClass:"tw-flex-1",attrs:{"value-type":"timestamp","label":"Start Date"},model:{value:(_vm.start),callback:function ($$v) {_vm.start=$$v},expression:"start"}}),_c('ui-date-picker2',{staticClass:"tw-flex-1",attrs:{"value-type":"timestamp","label":"Staking Rewards Start Date"},model:{value:(_vm.end),callback:function ($$v) {_vm.end=$$v},expression:"end"}}),_c('ui-button',{staticClass:"tw-mt-5",nativeOn:{"click":function($event){$event.stopPropagation();return _vm.createRec()}}},[_vm._v("Create")])],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }