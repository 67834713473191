





















































































































import Component from 'vue-class-component';

import DataApiManager from '@/api/dataApiManager';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiPagination from '@/components/ui/UiPagination.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';

import { standardizeState, startDownload } from './DataImportUtils';
import { WizardStatus } from './DataImportWizard.vue';
import { RowValueState } from './Explore.constants';

@Component({
  components: {
    UiButton,
    UiDropdown,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiDataTable,
    UiSelect2,
    UiTextEdit,
    UiDatePicker,
    UiPagination,
  },
})
export default class DataFeedReport extends BaseVue {
  public startTime = '';
  public endTime = '';
  public isLoadingPagination = false;
  public feedValidationWorkflowExecutionId = '';
  public feedCreationWorkflowExecutionId = '';
  public isLoading = 0;
  public validatedTransaction = '0';
  public createdTransaction = '0';
  public totalTransactions = '0';
  public state = 'running';
  public isLoadingDataSource = false;
  public feedStatus = WizardStatus.ValidatingRecords;

  public dataSourceId = '';
  public schemaId = '';
  public isDownloadLoading = false;
  public validationResults: any[] = [];
  public dataSources: any[] = [];
  public creationResults: any[] = [];
  public dataSourceName = '';
  public fileUrl = '';
  public pollImportExecutionResultsStarted = false;
  declare register?: any;
  public dataSource: { id: string; name: string } | null = null;
  public pageLimit = '10';
  public dataSourceItems: any[] = [];
  public additionalColumns: string[] = ['status', 'statusText'];
  public pageToken = 1;
  public validationFailedLength = 0;
  public creationFailedLength = 0;
  public dataLength = 0;
  public metaDataKeys: string[] = [];
  public rowClassConditionMap = (item: any) => ({
    'tw-bg-green-100': item.status === RowValueState.EntityCreated,
    'tw-bg-orange-100': item.status === RowValueState.EntityValidated,
    'tw-bg-red-100': [RowValueState.EntityCreateFailed, RowValueState.EntityInValidated].includes(item.status),
  });

  readonly headers = [];
  async mounted() {
    await this.loadDataSources();
    await this.pollFeedStatus();
  }

  public getProgressBarValue(type = 'Validation') {
    const numerator = type === 'Validation' ? this.validatedTransaction : this.createdTransaction;
    const progress = (parseInt(numerator, 10) / parseInt(this.totalTransactions, 10)) * 100;
    return isNaN(progress) ? 0 : progress;
  }

  async loadDataSources() {
    this.isLoadingDataSource = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.listDataSources(this.orgId, { withCredentials: true });
      if (resp.status === 200) {
        this.dataSources = resp.data.items;
      } else {
        const e = new Error('Bad response: ' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoadingDataSource = false;
    }
  }

  async pollFeedStatus() {
    const ds = DataApiManager.getInstance();
    const feedExecutionId = this.$route.params.feedExecutionId;
    while (![WizardStatus.Completed].includes(this.feedStatus)) {
      const wizardDataResponse = await ds.getFeedById(this.orgId, feedExecutionId, {
        withCredentials: true,
      });
      const record = wizardDataResponse.data.items[0];
      if (record) {
        this.validatedTransaction = record.ValidatedTransaction;
        this.dataSourceId = record.DataSourceId;
        this.schemaId = this.dataSources.filter((check: any) => check.id === this.dataSourceId)[0].schemaId;
        this.createdTransaction = record.CreatedTransaction;
        this.totalTransactions = record.TotalTransactions;
        this.startTime = record.StartTime;
        this.endTime = record.EndTime;
        this.feedValidationWorkflowExecutionId = record.ValidationWorkflowExecutionId;
        this.feedCreationWorkflowExecutionId = record.CreationWorkflowExecutionId;
        if (!this.pollImportExecutionResultsStarted) {
          this.pollImportExecutionResults();
        }
      }
    }
  }

  async pollImportExecutionResults() {
    this.pollImportExecutionResultsStarted = true;
    const ds = DataApiManager.getInstance();
    this.isLoading = 1;
    do {
      const workflowExecutionId = [WizardStatus.ValidatingRecords, WizardStatus.Validated].includes(this.feedStatus)
        ? this.feedValidationWorkflowExecutionId
        : this.feedCreationWorkflowExecutionId;
      const resp = await ds.loadImportExecutionResults(
        this.orgId,
        this.getWorkflowDefinitionId(),
        workflowExecutionId,
        this.pageToken,
        Number(this.pageLimit),
        { withCredentials: true }
      );

      if (resp.status === 200) {
        const { execution, results } = resp.data;
        if (resp.data.validationFailedLength) {
          this.validationFailedLength = resp.data.validationFailedLength;
        }
        if (resp.data.creationFailedLength) {
          this.creationFailedLength = resp.data.creationFailedLength;
        }
        const { items, dataLength } = results;
        this.validationResults = items;
        this.creationResults = items;
        this.dataLength = dataLength;
        const { state: newState, input } = execution;
        const { dataSourceId, dataSourceName } = input;
        this.state = newState;
        if (this.state === 'succeeded') {
          const workflowType = this.getWorkflowDefinitionId();
          if (workflowType === 'bulk-transaction-feed-validation-workflow') {
            this.feedStatus = WizardStatus.CreatingRecords;
          }
          if (
            ['bulk-btc-wallet-validation-workflow', 'bulk-transaction-feed-creation-workflow'].includes(workflowType)
          ) {
            this.feedStatus = WizardStatus.Completed;
          }
        }
        this.dataSourceId = dataSourceId;
        this.dataSourceName = dataSourceName;
        if (this.state === 'failed') {
          this.feedStatus = WizardStatus.Completed;
        }
      } else {
        const e = new Error('Bad response: ' + resp.status);
        console.log(e);
      }
      // wait for some time before making the next API call
      await new Promise((resolve) => setTimeout(resolve, 5000));
    } while (this.feedStatus !== WizardStatus.Completed);

    this.isLoading = 0;
  }

  getWorkflowExecutionId() {
    if (this.schemaId === 'btc-wallet') {
      return this.feedValidationWorkflowExecutionId;
    }
    return [WizardStatus.ValidatingRecords, WizardStatus.Validated].includes(this.feedStatus)
      ? this.feedValidationWorkflowExecutionId
      : this.feedCreationWorkflowExecutionId;
  }

  async downloadAsCSVFromFile() {
    try {
      this.isDownloadLoading = true;
      const workflowDefinitionId = this.getWorkflowDefinitionId();
      const workflowExecutionId = this.getWorkflowExecutionId();

      const ds = DataApiManager.getInstance();

      const response = await ds.getFileURLForExecutionResults(
        this.orgId,
        this.dataSourceName,
        workflowDefinitionId,
        workflowExecutionId,
        { withCredentials: true }
      );
      this.fileUrl = response.data.fileUrl;
      startDownload(this.fileUrl);
    } catch (error) {
      console.error('Error downloading CSV:', error);
      this.showErrorSnackbar((error as Error).message);
    } finally {
      this.isDownloadLoading = false;
    }
  }

  standardizeState(name: string): string {
    return standardizeState(name);
  }

  getWorkflowDefinitionId() {
    if (this.schemaId === 'btc-wallet') {
      return 'bulk-btc-wallet-validation-workflow';
    }
    return [WizardStatus.ValidatingRecords, WizardStatus.Validated].includes(this.feedStatus)
      ? 'bulk-transaction-feed-validation-workflow'
      : 'bulk-transaction-feed-creation-workflow';
  }

  async changePageLimit() {
    this.pageToken = 1;
    this.loadDataFromSelectedDataSource();
  }

  async loadDataFromSelectedDataSource() {
    const workflowExecutionId = [WizardStatus.ValidatingRecords, WizardStatus.Validated].includes(this.feedStatus)
      ? this.feedValidationWorkflowExecutionId
      : this.feedCreationWorkflowExecutionId;
    if (workflowExecutionId) {
      this.isLoadingPagination = true;
      await this.loadImportExecutionResults(this.getWorkflowDefinitionId(), workflowExecutionId);
      this.isLoadingPagination = false;
    }
  }

  async loadImportExecutionResults(workflowDefinitionId: string, workflowExecutionId: string) {
    this.isLoading = 1;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.loadImportExecutionResults(
        this.orgId,
        workflowDefinitionId,
        workflowExecutionId,
        this.pageToken,
        Number(this.pageLimit),
        { withCredentials: true }
      );
      if (resp.status === 200) {
        const { execution, results } = resp.data;
        const { items, dataLength } = results;
        if (
          [WizardStatus.ValidatingRecords, WizardStatus.Validated, WizardStatus.CreatingRecords].includes(
            this.feedStatus
          )
        ) {
          this.validationResults = items;
        } else {
          this.creationResults = items;
        }
        this.dataLength = dataLength;
        const { startTime, endTime, state, input } = execution;
        const { dataSourceId, dataSourceName } = input;
        this.startTime = startTime;
        this.endTime = endTime;
        this.state = state;
        this.dataSourceId = dataSourceId;
        this.dataSourceName = dataSourceName;
      } else {
        const e = new Error('Bad response: ' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoading = 0;
    }
  }

  public displayRegister(feedStatus: WizardStatus) {
    let results = [];
    if ([WizardStatus.ValidatingRecords, WizardStatus.Validated, WizardStatus.CreatingRecords].includes(feedStatus)) {
      results = this.validationResults;
    } else {
      results = this.creationResults;
    }
    return results;
  }

  public get displayHeaders() {
    let results = [];
    if (
      [WizardStatus.ValidatingRecords, WizardStatus.Validated, WizardStatus.CreatingRecords].includes(this.feedStatus)
    ) {
      results = this.validationResults;
    } else {
      results = this.creationResults;
    }

    if (results.length === 0) {
      return [];
    }
    const headers: any = Object.keys(results[0]).map((item) => ({
      id: item,
      label: item,
      defaultVisibility: !item.startsWith('metadata_'),
    }));
    return headers;
  }
}
