

































































































import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import DataApiManager from '@/api/dataApiManager';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiPagination from '@/components/ui/UiPagination.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { isDefined } from '@/utils/guards';

import { standardizeState, startDownload } from './DataImportUtils';
import { WizardStatus } from './DataImportWizard.vue';
import { RowValueState } from './Explore.constants';

export enum WizardState {
  Running = 'running',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

@Component({
  components: {
    UiButton,
    UiDropdown,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiDataTable,
    UiSelect2,
    UiTextEdit,
    UiDatePicker,
    UiPagination,
  },
})
export default class DataImportReport extends BaseVue {
  @Prop({ default: '' })
  public workflowExecutionId!: string;

  @Prop({ default: '' })
  public workflowDefinitionId!: string;

  @Prop({ required: true })
  public wizardReportId!: string;

  public startTime = '';
  public endTime = '';
  public isLoading = 0;
  public fileUrl = '';
  public validatedTransaction = '0';
  public createdTransaction = '0';
  public totalTransactions = '0';
  public state = WizardState.Running;
  public wizardStatus =
    this.workflowDefinitionId === 'bulk-transaction-validation-workflow'
      ? WizardStatus.ValidatingRecords
      : WizardStatus.CreatingRecords;

  public dataSourceId = '';
  public results: any[] = [];
  public dataSourceName = '';
  declare register?: any;
  public dataSource: { id: string; name: string } | null = null;
  public pageLimit = '10';
  public dataSourceItems: any[] = [];
  public additionalColumns: string[] = ['status', 'statusText'];
  public pageToken = 1;
  public isDownloadLoading = false;
  public validationFailedLength = 0;
  public creationFailedLength = 0;
  public dataLength = 0;
  public rowClassConditionMap = (item: any) => ({
    'tw-bg-green-100': item.status === RowValueState.EntityCreated,
    'tw-bg-orange-100': item.status === RowValueState.EntityValidated,
    'tw-bg-red-100': [RowValueState.EntityCreateFailed, RowValueState.EntityInValidated].includes(item.status),
  });

  public pollingInterval: any;
  public pollingTimeout: any;

  readonly headers = [];

  mounted() {
    // start polling
    this.startPolling();

    // schedule stop polling after 1 hour
    const maxPollingTime = 3600_000; // 1 hour -> 60 * 60 * 1000
    this.pollingTimeout = setTimeout(() => {
      this.stopPolling();
    }, maxPollingTime);
  }

  beforeDestroy() {
    this.stopPolling();
    // remove timeout
    if (this.pollingTimeout) clearTimeout(this.pollingTimeout);
  }

  public async startPolling() {
    // avoid duplicated polling intervals
    if (this.pollingInterval) clearInterval(this.pollingInterval);

    // check for invalid state of wizard
    // if wizard step if different from Creating or Validating stop
    if (
      this.wizardStatus &&
      ![WizardStatus.CreatingRecords, WizardStatus.ValidatingRecords].includes(this.wizardStatus)
    ) {
      this.stopPolling();
      return;
    }

    const workflowDefinitionId = this.workflowDefinitionId ?? this.$route.params.worfklowDefinitionId;
    const workflowExecutionId = this.workflowExecutionId ?? this.$route.params.workflowExecutionId;
    // start loading
    this.isLoading = 1;

    try {
      // run first call
      await Promise.all([
        this.pollWizardStatus(),
        this.pollImportExecutionResults(workflowDefinitionId, workflowExecutionId),
      ]);

      // exit if has failure or completed with the first call
      if (
        [WizardStatus.ValidatingRecords, WizardStatus.CreatingRecords].indexOf(this.wizardStatus) > -1 &&
        this.state === WizardState.Failed
      ) {
        console.log('Failed validation');
        return;
      } else if (this.wizardStatus === WizardStatus.Completed) {
        console.log('Completed import');
        return;
      }

      // start polling - timeout
      const intervalTime = 5_000; // 5 seconds / to refresh status

      // register interval
      this.pollingInterval = setInterval(async () => {
        await Promise.all([
          this.pollWizardStatus(),
          this.pollImportExecutionResults(workflowDefinitionId, workflowExecutionId),
        ]);
      }, intervalTime);
    } catch (error) {
      // handle error
      this.stopPolling();
    } finally {
      // stop loading
      if (this.state !== WizardState.Running) {
        this.isLoading = 0;
      }
    }
  }

  public stopPolling() {
    if (this.pollingInterval) clearInterval(this.pollingInterval);
    this.isLoading = 0;
  }

  public getProgressBarValue() {
    const numerator =
      this.workflowDefinitionId === 'bulk-transaction-validation-workflow'
        ? this.validatedTransaction
        : this.createdTransaction;
    const progress = (parseInt(numerator, 10) / parseInt(this.totalTransactions, 10)) * 100;
    return isNaN(progress) ? 0 : progress;
  }

  async pollWizardStatus() {
    const ds = DataApiManager.getInstance();

    // completed validation
    if (
      (this.state === WizardState.Succeeded && this.wizardStatus === WizardStatus.Validated) ||
      (this.state === WizardState.Succeeded && this.wizardStatus === WizardStatus.Completed)
    ) {
      // completed stop
      this.stopPolling();
      return;
    }

    try {
      const wizardDataResponse = await ds.getWizardReportById(this.orgId, this.wizardReportId, {
        withCredentials: true,
      });

      const record = wizardDataResponse.data.items[0];
      if (record) {
        this.validatedTransaction = record.ValidatedTransaction;
        this.createdTransaction = record.CreatedTransaction;
        this.totalTransactions = record.TotalTransactions;
        this.wizardStatus = record.Status;
      }

      // stop polling criteria
      // if (this.wizardStatus === WizardStatus.Validated && this.state === WizardState.Succeeded)
      // if ([WizardStatus.Completed, WizardStatus.Validated].indexOf(this.wizardStatus) > -1) this.stopPolling();
    } catch (error) {
      this.stopPolling();
      this.$emit('statusChange', 'failed');
    }
  }

  async pollImportExecutionResults(workflowDefinitionId: string, workflowExecutionId: string) {
    if (!workflowDefinitionId || !workflowExecutionId) {
      console.log('workflow data missing');
      this.stopPolling();
      return null;
    }

    const ds = DataApiManager.getInstance();

    if (this.state === WizardState.Running) {
      const resp = await ds.loadImportExecutionResults(
        this.orgId,
        workflowDefinitionId,
        workflowExecutionId,
        this.pageToken,
        Number(this.pageLimit),
        { withCredentials: true }
      );

      if (resp.status === 200) {
        const { execution, results } = resp.data;
        if (resp.data.validationFailedLength) {
          this.validationFailedLength = resp.data.validationFailedLength;
        }
        if (resp.data.creationFailedLength) {
          this.creationFailedLength = resp.data.creationFailedLength;
        }
        const { items, dataLength } = results;

        this.results = items;
        this.dataLength = dataLength;
        const { startTime, endTime, state: newState, input } = execution;
        const { dataSourceId, dataSourceName } = input;
        this.startTime = startTime;
        this.endTime = endTime;
        this.state = newState;
        this.dataSourceId = dataSourceId;
        this.dataSourceName = dataSourceName;
      } else {
        const e = new Error('Bad response: ' + resp.status);
        this.showErrorSnackbar((e as Error).message);
        this.state = WizardState.Failed;
      }
    }

    if (this.state === WizardState.Failed) {
      this.stopPolling();
      this.$emit('statusChange', 'failed');
    }

    // the execution is no longer "running", so do something else
    console.log('Execution polling is no longer running ::', this.state);
  }

  async downloadAsCSVFromFile() {
    try {
      this.isDownloadLoading = true;
      const workflowDefinitionId = this.workflowDefinitionId || this.$route.params.workflowDefinitionId;
      const workflowExecutionId = this.workflowExecutionId || this.$route.params.workflowExecutionId;

      const ds = DataApiManager.getInstance();

      const response = await ds.getFileURLForExecutionResults(
        this.orgId,
        this.dataSourceName,
        workflowDefinitionId,
        workflowExecutionId,
        { withCredentials: true }
      );
      this.fileUrl = response.data.fileUrl;
      startDownload(this.fileUrl);
    } catch (error) {
      console.error('Error downloading CSV:', error);
      this.showErrorSnackbar((error as Error).message);
    } finally {
      this.isDownloadLoading = false;
    }
  }

  standardizeState(name: string): string {
    return standardizeState(name);
  }

  async loadDataFromSelectedDataSource() {
    const workflowDefinitionId = this.workflowDefinitionId ?? this.$route.params.worfklowDefinitionId;
    const workflowExecutionId = this.workflowExecutionId ?? this.$route.params.workflowExecutionId;

    await this.loadImportExecutionResults(workflowDefinitionId, workflowExecutionId);
  }

  async loadImportExecutionResults(workflowDefinitionId: string, workflowExecutionId: string) {
    this.isLoading = 1;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.loadImportExecutionResults(
        this.orgId,
        workflowDefinitionId,
        workflowExecutionId,
        this.pageToken,
        Number(this.pageLimit),
        { withCredentials: true }
      );
      if (resp.status === 200) {
        const { execution, results } = resp.data;
        const { items, dataLength } = results;
        this.results = items;
        this.dataLength = dataLength;
        const { startTime, endTime, state, input } = execution;
        const { dataSourceId, dataSourceName } = input;
        this.startTime = startTime;
        this.endTime = endTime;
        this.state = state;
        this.dataSourceId = dataSourceId;
        this.dataSourceName = dataSourceName;
      } else {
        const e = new Error('Bad response: ' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoading = 0;
    }
  }

  public get displayRegister() {
    return this.isLoading ? [] : (this.results ?? []).filter(isDefined);
  }

  public get displayHeaders() {
    if (this.results.length === 0) {
      return [];
    }
    const headers: any = Object.keys(this.results[0]).map((item) => ({
      id: item,
      label: item,
      defaultVisibility: true,
    }));
    return headers.filter((item: any) => !item.label.includes('metadata_'));
  }

  @Watch('state')
  onStateChanged(val: any, prev: any) {
    this.$emit('statusChange', val);
  }
}
