var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-text-gray-900 tw-font-sans",staticStyle:{"font-size":"16px"}},[_vm._m(0),_c('div',{staticClass:"tw-shadow-sm"},[_c('div',{staticClass:"tw-flex tw-items-center tw-pl-4 tw-pb-2 tw-pt-2"},[(_vm.isLoading)?_c('ui-loading',{staticClass:"tw-text-xl tw-h-4 tw-mt-2 tw-mr-2 tw-align-top"}):_vm._e(),_c('div',{staticClass:"tw-text-gray-500 tw-text-sm tw-mb-2"},[_vm._v("Data Source Name: "+_vm._s(_vm.dataSourceName))]),_c('div',{staticClass:"tw-text-gray-500 tw-ml-6 tw-text-sm tw-mb-2"},[_vm._v("Status: "+_vm._s(_vm.standardizeState(_vm.state)))]),(
          _vm.totalTransactions !== '0' &&
          _vm.totalTransactions !== '' &&
          _vm.totalTransactions !== null &&
          _vm.workflowDefinitionId === 'bulk-transaction-validation-workflow'
        )?_c('div',{staticClass:"tw-text-gray-500 tw-ml-6 tw-text-sm tw-mb-2"},[_vm._v(" Validation Data Processing Stats: "+_vm._s(_vm.validatedTransaction)+" / "+_vm._s(_vm.totalTransactions)+" ")]):_vm._e(),(
          _vm.totalTransactions !== '0' &&
          _vm.totalTransactions !== '' &&
          _vm.totalTransactions !== null &&
          _vm.workflowDefinitionId === 'bulk-transaction-creation-workflow'
        )?_c('div',{staticClass:"tw-text-gray-500 tw-ml-6 tw-text-sm tw-mb-2"},[_vm._v(" Creation Data Processing Stats: "+_vm._s(_vm.createdTransaction)+" / "+_vm._s(_vm.totalTransactions)+" ")]):_vm._e(),(_vm.state !== 'running' && _vm.workflowDefinitionId === 'bulk-transaction-validation-workflow')?_c('div',{staticClass:"tw-text-gray-500 tw-ml-6 tw-text-sm tw-mb-2"},[_vm._v(" Transactions Failing Validation: "+_vm._s(_vm.validationFailedLength)+" ")]):_vm._e(),(_vm.state !== 'running' && _vm.workflowDefinitionId === 'bulk-transaction-creation-workflow')?_c('div',{staticClass:"tw-text-gray-500 tw-ml-6 tw-text-sm tw-mb-2"},[_vm._v(" Transactions Failing Creation: "+_vm._s(_vm.creationFailedLength)+" ")]):_vm._e(),_c('div',{staticClass:"tw-ml-6 tw-align-center"},[_c('ui-select',{attrs:{"values":['10', '20', '50', '100', '250'],"suffix":"Results","sub-label":"Display Results"},on:{"input":_vm.loadDataFromSelectedDataSource},model:{value:(_vm.pageLimit),callback:function ($$v) {_vm.pageLimit=$$v},expression:"pageLimit"}})],1),_c('div',{staticClass:"tw-ml-6 tw-align-center"},[_c('ui-button',{attrs:{"disabled":!!_vm.isLoading,"loading":_vm.isDownloadLoading},nativeOn:{"click":function($event){return _vm.downloadAsCSVFromFile.apply(null, arguments)}}},[_vm._v("Download as CSV")])],1)],1),(!!_vm.isLoading)?_c('v-progress-linear',{attrs:{"value":_vm.getProgressBarValue(),"color":"accent","height":"25"}}):_vm._e(),_c('ui-data-table',{attrs:{"headers":_vm.displayHeaders,"isLoading":!!_vm.isLoading,"items":_vm.displayRegister,"noDataMessage":"There are no transactions to display.","hideable-columns":"","rowClassConditionMap":_vm.rowClassConditionMap,"resizeable-columns":""},scopedSlots:_vm._u([_vm._l((_vm.displayHeaders),function(header){return {key:("td-" + (header.id)),fn:function(ref){
        var dataSourceItem = ref.item;
return [_c('td',{key:header.id},[_vm._v(" "+_vm._s(dataSourceItem[header.label])+" ")])]}}})],null,true)}),(_vm.displayRegister.length !== 0)?_c('ui-pagination',{attrs:{"total":_vm.dataLength,"itemsPerPage":parseInt(_vm.pageLimit, 10)},on:{"input":_vm.loadDataFromSelectedDataSource},model:{value:(_vm.pageToken),callback:function ($$v) {_vm.pageToken=$$v},expression:"pageToken"}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-border-b tw-border-gray-300"},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5"},[_c('h3',{staticClass:"tw-text-2xl tw-capitalize tw-font-medium"},[_vm._v("Data Import Report")])])])}]

export { render, staticRenderFns }