





























































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import DataApiManager from '@/api/dataApiManager';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiPagination from '@/components/ui/UiPagination.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { isDefined } from '@/utils/guards';

import { DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner } from '../../../generated/data-svc';
import { DEFAULT_PAGE_LIMIT, RowValueState } from './Explore.constants';

@Component({
  components: {
    UiButton,
    UiDropdown,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiDataTable,
    UiSelect2,
    UiTextEdit,
    UiDatePicker,
    UiPagination,
  },
})
export default class Explore extends BaseVue {
  @Prop()
  public readonly dataSourceId?: string;

  @Prop({ default: true })
  public readonly showHeading?: boolean;

  @Prop({ default: true })
  public readonly showStatusCols?: boolean;

  @Prop({ default: 'transaction' })
  public readonly schemaId?: string;

  @Prop({ default: false })
  public readonly isSummaryView?: boolean;

  @Prop({ default: false })
  public readonly isTransformedView?: boolean;

  declare register?: any;
  public dataSources: DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner[] = [];
  public dataSource: { id: string; name: string } | null = null;
  public pageLimit = DEFAULT_PAGE_LIMIT;
  public dataSourceItems: any[] = [];
  public additionalColumns: string[] = this.showStatusCols ? ['status', 'statusText'] : [];
  public pageToken = 1;
  public dataLength = 0;
  public metaDataKeys: string[] = [];
  public rowClassConditionMap = (item: any) => ({
    'tw-bg-green-100': item.status === RowValueState.EntityCreated,
    'tw-bg-orange-100': item.status === RowValueState.EntityValidated,
    'tw-bg-red-100': item.status === RowValueState.EntityInValidated,
  });

  readonly headers = [];
  async mounted() {
    await this.loadDataSources();
    if (this.dataSourceId) {
      await this.loadDataFromSelectedDataSource();
    }
  }

  async onDataSourceArgChanged() {
    // Reset page token and keys before loading another data source
    this.pageToken = 1;
    this.metaDataKeys = [];
    await this.loadDataFromSelectedDataSource();
  }

  async loadDataFromSelectedDataSource() {
    if (this.dataSource === null && !this.dataSourceId) return;
    const dataSourceId = this.dataSourceId ? this.dataSourceId : this.dataSource?.id;
    this.isLoading = 1;
    let resp: any = null;
    try {
      const ds = DataApiManager.getInstance();
      if (this.isSummaryView) {
        resp = await ds.getDataSourceSummary(
          this.orgId,
          dataSourceId ?? '',
          this.schemaId ?? 'transaction',
          `${this.pageToken}`,
          Number(this.pageLimit),
          {
            withCredentials: true,
          }
        );
      } else if (this.isTransformedView) {
        resp = await ds.getDataSourceRows(
          this.orgId,
          dataSourceId ?? '',
          this.schemaId ?? 'transaction',
          `${this.pageToken}`,
          Number(this.pageLimit),
          {
            withCredentials: true,
          }
        );
      } else {
        resp = await ds.getDataSourceRows(
          this.orgId,
          dataSourceId ?? '',
          'transaction',
          `${this.pageToken}`,
          Number(this.pageLimit),
          {
            withCredentials: true,
          }
        );
      }
      if (resp.status === 200) {
        this.dataSourceItems = resp.data.items;
        this.dataLength = resp.data.dataLength;
        if (resp.data.items.length > 0) {
          this.metaDataKeys = Object.keys(resp.data.items[0]).filter((item) => item.startsWith('metadata_'));
        } else {
          this.metaDataKeys = [];
        }
      } else {
        if (resp.data.message.includes('Not found: Table')) {
          this.showErrorSnackbar(`Add Data to the Datasource ${this.dataSource?.name} before you start Exploring`);
        } else {
          this.showErrorSnackbar(resp.message);
        }
      }
    } catch (err: any) {
      if (err.message && typeof err.message === 'string') {
        this.showErrorSnackbar(err.message);
      } else {
        this.showErrorSnackbar('Internal Server Error');
      }
    } finally {
      this.isLoading = 0;
    }
  }

  async loadDataSources() {
    this.isLoading = 1;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.listDataSources(this.orgId, { withCredentials: true });
      if (resp.status === 200) {
        this.dataSources = resp.data.items;
        this.dataLength = resp.data.items.length;
      } else {
        const e = new Error('Bad response: ' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoading = 0;
    }
  }

  public isLoading = 0;
  public startDate: string | null = null;
  public endDate = new Date().toISOString().substring(0, 10);

  public get displayRegister() {
    console.log(this.dataSourceItems);
    return this.isLoading ? [] : (this.dataSourceItems ?? []).filter(isDefined);
  }

  public get displayHeaders() {
    const dataSourceId = this.dataSourceId ? this.dataSourceId : this.dataSource?.id;
    const selectedDataSource = this.dataSources.filter((item) => item.id === dataSourceId)[0];
    const importSummarySchema = [
      { name: 'asset', type: 'string' },
      { name: 'deposits', type: 'string' },
      { name: 'tradeAcquisitions', type: 'string' },
      { name: 'withdrawals', type: 'string' },
      { name: 'tradeDisposals', type: 'string' },
      { name: 'fees', type: 'string' },
      { name: 'tradeFees', type: 'string' },
      { name: 'total_acquisitions', type: 'string' },
      { name: 'total_disposals', type: 'string' },
      { name: 'total_fees', type: 'string' },
      { name: 'net_change', type: 'string' },
    ];
    const systemTransactionSchema = [
      { name: 'id', type: 'string' },
      { name: 'remoteContactId', type: 'string' },
      { name: 'amount', type: 'string' },
      { name: 'amountTicker', type: 'string' },
      { name: 'cost', type: 'string' },
      { name: 'costTicker', type: 'string' },
      { name: 'fee', type: 'string' },
      { name: 'feeTicker', type: 'string' },
      { name: 'time', type: 'string' },
      { name: 'blockchainId', type: 'string' },
      { name: 'memo', type: 'string' },
      { name: 'transactionType', type: 'string' },
      { name: 'accountId', type: 'string' },
      { name: 'contactId', type: 'string' },
      { name: 'categoryId', type: 'string' },
      { name: 'taxExempt', type: 'string' },
      { name: 'tradeId', type: 'string' },
      { name: 'description', type: 'string' },
      { name: 'fromAddress', type: 'string' },
      { name: 'toAddress', type: 'string' },
      { name: 'groupId', type: 'string' },
    ];
    const systemBtcWalletSchema = [
      { name: 'id', type: 'string' },
      { name: 'walletAddress', type: 'string' },
      { name: 'loadID', type: 'string' },
    ];
    let schemaHeader = null;
    if (this.isSummaryView) {
      schemaHeader = this.schemaToHeader(importSummarySchema);
    } else if (this.isTransformedView) {
      if (this.schemaId === 'btc-wallet') {
        schemaHeader = this.schemaToHeader(systemBtcWalletSchema);
      } else {
        schemaHeader = this.schemaToHeader(systemTransactionSchema);
      }
    } else {
      schemaHeader = selectedDataSource ? this.schemaToHeader(selectedDataSource.schema?.fields) : [];
    }
    return selectedDataSource ? schemaHeader : [];
  }

  public schemaToHeader(schemaFields: any[]) {
    const headers: any = [];
    for (const field of schemaFields) {
      headers.push({
        id: field.name,
        label: field.name,
        defaultVisibility: true,
      });
    }
    this.additionalColumns.map((key) => {
      headers.push({
        id: key,
        label: key,
        defaultVisibility: true,
      });
    });
    this.metaDataKeys.map((key) => {
      headers.push({
        id: key,
        label: key,
        defaultVisibility: false,
      });
    });

    return headers;
  }
}
