













































































































































import { DataSvcSchema } from 'generated/data-svc';
import { v4 as uuid } from 'uuid';
import { Component } from 'vue-property-decorator';

import DataApiManager from '@/api/dataApiManager';
import { BaseVue } from '@/BaseVue';
import DataSourceCreate from '@/components/data/DataSourceCreate.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiPagination from '@/components/ui/UiPagination.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiStepper from '@/components/ui/UiStepper.vue';
import { getDataSourceName, standardizeState } from '@/views/data/DataImportUtils';

import Explore from '../../views/data/Explore.vue';
import ImportPreview from '../tools/ImportPreview.vue';
import UploadImport from '../tools/UploadImport.vue';
import ValidateImport from '../tools/ValidateImport.vue';
import DataSourceUpload from './DataSourceUpload.vue';

@Component({
  components: {
    UiButton,
    UiDataTable,
    UiLoading,
    UiSelect,
    UiPagination,
    UiStepper,
    UploadImport,
    DataSourceCreate,
    ImportPreview,
    ValidateImport,
    DataSourceUpload,
    Explore,
  },
})
export default class DataImport extends BaseVue {
  getDataSourceName(dataSourceId: any, dataSources: any) {
    return getDataSourceName(dataSourceId, dataSources);
  }

  getDataSourceSchemaName(schemaId: any, schemas: DataSvcSchema[]) {
    return getDataSourceName(schemaId, schemas);
  }

  declare register?: any;

  public dataSourceId = '';
  public isLoadingDataSource = false;
  public isLoadingSchemas = false;
  public isLoadingReports = false;
  public pageLimit = '10';
  public executionReports: any = [];
  public dataSources: any = [];
  public schemas: DataSvcSchema[] = [];
  public pageToken = 1;
  public reportsLength = 0;

  async mounted() {
    await this.loadRequiredData();
  }

  async onDataSourceArgChanged() {
    // Reset page token and keys before loading another data source
    this.pageToken = 1;
    await this.loadWizardReports();
  }

  async loadRequiredData() {
    const loadDataSourceAndSchema = [this.loadSchemas(), this.loadDataSources()];
    await Promise.all(loadDataSourceAndSchema);
    await this.loadWizardReports();
  }

  async loadSchemas() {
    this.isLoadingSchemas = true;
    try {
      const ds = DataApiManager.getInstance();

      const [respSchemas, respCustomSchemas] = await Promise.all([
        ds.listSchemas(this.orgId, { withCredentials: true }),
        ds.listCustomSchemas(this.orgId, { withCredentials: true }),
      ]);
      if (respSchemas.status === 200 && respCustomSchemas.status === 200) {
        const allSchemas = [...respSchemas.data.items, ...respCustomSchemas.data.items];
        // Only show enabled styles
        this.schemas = allSchemas
          .filter((item) => item.status === 'Enabled')
          .sort((a, b) => a.name.localeCompare(b.name));
      }
    } catch (e) {
      console.log('Problem loading schemas', e);
      this.showErrorSnackbar('Problem loading schemas');
    } finally {
      this.isLoadingSchemas = false;
    }
  }

  async loadDataSources() {
    this.isLoadingDataSource = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.listDataSources(this.orgId, { withCredentials: true });
      if (resp.status === 200) {
        this.dataSources = resp.data.items;
      } else {
        const e = new Error('Bad response: ' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoadingDataSource = false;
    }
  }

  navigateToNewImportWizard() {
    const wizardReportId = uuid();
    this.$router.push(`/importv2/wizard/${wizardReportId}`);
  }

  viewReports(wizardReportId: string): void {
    this.$router.push(`/importv2/wizard/${wizardReportId}`);
  }

  standardizeState(name: string): string {
    return standardizeState(name);
  }

  async loadWizardReports() {
    this.isLoadingReports = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.getWizardReports(this.orgId, this.pageToken, parseInt(this.pageLimit, 10), {
        withCredentials: true,
      });
      if (resp.status === 200) {
        this.executionReports = resp.data.items.map((item) => {
          return {
            ...item,
            createdBy: JSON.parse(item.CreatedBy).name,
            lastModifiedBy: JSON.parse(item.LastModifiedBy).name,
            wizardReportId: item.WizardReportId,
            createdAt: item.CreatedAt,
            lastModifiedAt: item.LastModifiedAt,
            schemaId: item.SchemaId,
            name,
            dataSourceId: item.DataSourceId,
            state: item.Status,
          };
        });
        this.reportsLength = resp.data.dataLength;
      } else {
        const e = new Error('Bad response: ' + resp.status);
        console.log((e as Error).message);
        this.showErrorSnackbar('Something went wrong, Please try again later.');
      }
    } catch (err) {
      console.log((err as Error).message);
      this.showErrorSnackbar('Something went wrong, Please try again later.');
    } finally {
      this.isLoadingReports = false;
    }
  }

  readonly headers = [
    {
      id: 'icon',
      label: '',
      defaultVisibility: true,
    },
    {
      id: 'wizardReportId',
      label: 'Import Id',
      defaultVisibility: true,
    },
    {
      id: 'dataSourceName',
      label: 'Import Name',
      defaultVisibility: true,
    },
    {
      id: 'name',
      label: 'File Type',
      defaultVisibility: true,
    },
    {
      id: 'createdBy',
      label: 'Created By',
      defaultVisibility: true,
    },
    {
      id: 'createdAt',
      label: 'Created At',
      defaultVisibility: true,
    },
    {
      id: 'lastModifiedBy',
      label: 'Last Edit By',
      defaultVisibility: true,
    },
    {
      id: 'lastModifiedAt',
      label: 'Last Edit At',
      defaultVisibility: true,
    },
    {
      id: 'state',
      label: 'status',
      defaultVisibility: true,
    },
    {
      id: 'actions',
      label: 'Actions',
      defaultVisibility: true,
    },
  ];

  toRounded(valStr: string, decimals: number) {
    const fixedString = Number(valStr).toFixed(decimals);
    return Number(fixedString);
  }
}
