export const standardizeState = (name: string): string => {
  switch (name) {
    case 'succeeded':
      return 'Data Processed Successfully';
    case 'failed':
      return 'Data Processing Failed';
    case 'running':
      return 'Running';
    default:
      return name;
  }
};

export const getDataSourceName = (dataSourceId: string, dataSources: any[]): string => {
  if (dataSources.length) {
    const item = dataSources.filter((item: { id: string }) => item.id === dataSourceId)[0];
    return item ? item?.name : dataSourceId;
  }
  return dataSourceId;
};

export const getDataSourceSchemaName = (schemaId: string, schemas: any[]): string => {
  if (schemas.length) {
    const item = schemas.filter((item: { id: string }) => item.id === schemaId)[0];
    return item ? item?.name : schemaId;
  }
  return schemaId;
};

export const getDataSourceSchemaNameByDataSourceId = (
  dataSourceId: string,
  dataSources: any[],
  schemas: any[]
): string => {
  if (dataSources.length) {
    const item = dataSources.filter((item: { id: string }) => item.id === dataSourceId)[0];
    return item ? getDataSourceSchemaName(item?.schemaId, schemas) : dataSourceId;
  }
  return dataSourceId;
};

export const startDownload = (fileUrl: string) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = 'data.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
