















import Component from 'vue-class-component';

import { BaseVue } from '../../BaseVue';
import ImportTransactions from '../../components/tools/Import.vue';
@Component({
  components: {
    ImportTransactions,
  },
})
export default class ImportClass extends BaseVue {
  public selectedTab = 0;
}
