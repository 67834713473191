

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Uitabs2 extends Vue {
  @Prop({ type: Array, required: true }) public tabList!: string[];
  @Prop({ type: String, required: false, default: 'vertical' }) public variant!: 'horizontal' | 'vertical';
  @Prop({ type: String, required: false, default: 'tw-p-4' }) public panelClasses!: string;
  @Prop({ type: String, required: false, default: '' }) public containerClasses!: string;
  @Prop({ type: String, required: false, default: '' }) public listClasses!: string;
  @Prop({ type: String, required: false, default: 'primary' }) public color!: string;

  public activeTab = 1;

  public get uid() {
    return this.$attrs._uid || ''; // Access _uid via $attrs
  }

  public get calcContainerClasses(): { [key: string]: boolean } {
    return {
      ...this.containerClasses.split(' ').reduce((a, x) => {
        a[x] = true;
        return a;
      }, {} as any),
      flex: this.variant === 'horizontal',
    };
  }

  public get calcListClasses(): { [key: string]: boolean } {
    return {
      ...this.listClasses.split(' ').reduce((a, x) => {
        a[x] = true;
        return a;
      }, {} as any),
      flex: this.variant === 'vertical',
    };
  }

  public getTabClass(id: number): string {
    if (this.color === 'primary') {
      if (id === this.activeTab) {
        return 'tw-border-primary-300 tw-text-gray-700 tw-whitespace-nowrap tw-pb-4 tw-px-3 tw-border-b-2 tw-font-medium tw-text-sm';
      } else {
        return 'tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300 tw-whitespace-nowrap tw-pb-4 tw-px-3 tw-border-b-2 tw-font-medium tw-text-sm';
      }
    } else if (this.color === 'secondary') {
      if (id === this.activeTab) {
        return 'tw-bg-neutral-500 tw-text-neutral-100 tw-px-4 tw-py-4 tw-font-medium tw-text-sm tw-truncate ';
      } else {
        return 'tw-text-gray-500 hover:tw-text-gray-700 tw-px-4 tw-py-4 tw-font-medium tw-text-sm tw-truncate ';
      }
    } else {
      return '';
    }
  }
}
